import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"

const TailoredServices = () => (
  <Layout>
    <SEO title="Tailored Services" />
    <HeroInner title="Tailored Services" />
  </Layout>
)

export default TailoredServices